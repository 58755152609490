//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import messaggi_mixin from "../mixin/messaggi_mixin";
import MessaggioElement from "./messaggi_element_mobile";
import NuovaConversazione from "./nuova_conversazione_mobile"
export default {
  components: {
    MessaggioElement,
    NuovaConversazione
  },
  mixins: [messaggi_mixin],
  methods:{
    nuova_conv(){
      this.$refs.nuova.open()
    }
  }
};
