import moment from 'moment'
export default {
    props: {
        messaggio: Object,
        id_conversazione: String,
    },
    mounted() {
        this.$nextTick(function () {
            if (!this.mioMsg && !this.messaggio.letto) {
                this.$store.dispatch("send_read_confirm", {
                    id_conversazione: this.id_conversazione,
                    id_messaggio: this.messaggio._id.$oid,
                });
            }
        });
    },
    computed: {
        mioMsg() {
            return this.messaggio.autore.iva == this.$store.getters.get_utente.iva;
        },
        ora() {
            return moment.unix(this.messaggio.ts_creazione).calendar();
        },
        iniz() {
            return this.messaggio.autore.nome.slice(0, 1).toUpperCase()
        }
    },
};