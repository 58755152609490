import moment from 'moment'
export default {
    props: {
        messaggio: Object
    },
    data() {
        return {
            show_chat: false
        }
    },
    computed: {

        data_conversazione() {
            return moment.unix(this.messaggio.ts_creazione).format("DD MMM YYYY HH:mm")
        },
        ordine() {
            return this.$store.getters.get_ordine_by_numero(this.messaggio.rif_ordine)
        },
        icon() {
            let letto = this.messaggio.messaggi.filter(msg =>
                msg.autore.iva != this.$store.getters.get_utente.iva
            ).some(msg => !msg.letto)
            let autore = this.messaggio.autore.iva == this.$store.getters.get_utente.iva
            if (autore) {
                return {
                    icon: letto ? 'mdi-email-send' : 'mdi-email-send-outline',
                    color: letto ? 'green' : 'grey'
                }
            } else {
                return {
                icon: letto ? 'mdi-email-receive' : 'mdi-email-receive-outline',
                color: letto ? 'orange' : 'grey'
            }
            }
            
        },
        letto() {
            return this.messaggio.messaggi.filter(msg =>
                msg.autore.iva != this.$store.getters.get_utente.iva
            ).some(msg => !msg.letto)
        }
    }
}