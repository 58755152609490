//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import messaggio_element_mixin from "../mixin/messaggio_element_mixin";
import moment from "moment";
import MessaggioDett from "./messaggio_dettaglio_mobile";
export default {
  components: {
    MessaggioDett,
  },
  mixins: [messaggio_element_mixin],
  computed: {
    data_conver() {
      if (moment.unix(this.messaggio.ts_creazione).isSame(moment())) {
        return moment.unix(this.messaggio.ts_creazione).format("HH:mm");
      } else {
        return moment.unix(this.messaggio.ts_creazione).format("DD MMM");
      }
    },
  },
  methods: {
    apri() {
      this.$refs.msg.open();
    },
  },
};
