//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

//import axios from "axios";
export default {
  props: {
    ordine: Object,
    show: Boolean,
  },
  data() {
    return {
      new_msg: "",
      object: "",
      dialog: false,
      destinatario: null,
      valid: true,
      rules: {
        req: (v) => !!v || "Campo vuoto",
      },
      loading: false,
      msg_sent: false,
      msg_error: false
    };
  },
  watch: {
    $route(to, from) {
      if (from.hash === "#nuovo-messaggio" && this.dialog) {
        this.dialog = false;
      }
    },
  },
  computed: {
    destinatari() {
      return this.$store.getters.get_destinatari;
    },
    utente() {
      return this.$store.getters.get_utente;
    },
  },
  methods: {
    open() {
      this.dialog = true;
      this.$router.push("#nuovo-messaggio");
    },
    close() {
      this.dialog = false;
      this.$router.back();
    },
    send_new() {
      this.$refs.form.validate();
      if (!this.valid) {
        return;
      }
      this.loading = true;
      let n_ordine = this.ordine ? this.ordine.numero.toString() : null;
      this.$store
        .dispatch("new_conversation", {
          rif_ordine: n_ordine,
          destinatario: this.destinatario,
          titolo: this.object,
          msg: this.new_msg,
        })
        .then(() => {
          this.msg_sent = true;
            setTimeout(() => {
                this.msg_sent = false
            }, 2000);
            this.dialog = false;
          this.$refs.form.reset();
        })
        .catch(()=>{
            this.msg_error = true
            setTimeout(() => {
                this.msg_error = false
            }, 2000);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
