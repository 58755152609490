import moment from 'moment'
export default {
    props: {
        messaggi: Array
    },
    mounted() {
        moment.locale("it_IT")
    },
    data() {
        return {
            show_nuova: false
        }
    }
}