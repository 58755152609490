//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Messaggio from "./messaggio_chat_mobile";
export default {
  components: {
    Messaggio,
  },
  props: {
    messaggio: Object,
  },
  data() {
    return {
      dialog: false,
      text_tosend: "",
      dialog_delete: false
    };
  },
  watch: {
    $route(to, from) {
      if (from.hash === "#messaggio" && this.dialog) {
        this.dialog = false;
      }
    },
  },
  methods: {
    open() {
      this.dialog = true;
      this.$router.push("#messaggio");
    },
    close() {
      this.dialog = false;
      this.$router.back();
    },
    send_msg() {
      this.$store
        .dispatch("send_msg", {
          id_conversazione: this.messaggio.id,
          body: this.text_tosend,
        })
        .then(() => {
          //this.scroll_down();
        });
      this.text_tosend = "";
    },
    elimina() {
      this.$store.dispatch("delete_conversation", this.messaggio.id);
    },
  },
};
